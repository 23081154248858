import { fetchLovBodies } from 'driverama-core/api/driverama/lov/lovBodies'
import { fetchLovDrives } from 'driverama-core/api/driverama/lov/lovDrives'
import { fetchLovFuelTypes } from 'driverama-core/api/driverama/lov/lovFuelTypes'
import { fetchLovMakes } from 'driverama-core/api/driverama/lov/lovMakes'
import { fetchLovModels } from 'driverama-core/api/driverama/lov/lovModelsSearch'
import { fetchLovTransmissions } from 'driverama-core/api/driverama/lov/lovTransmissions'
import { atom } from 'jotai'

export async function prefetchSerpLov() {
  // TODO: discuss with BE, whether it is possible to provide
  // slug friendly names without the need to effectively
  // fetch LOVs twice when using non-english language
  const headers = { 'Accept-Language': 'en' }

  const [
    makeList,
    modelList,
    bodyList,
    fuelTypeList,
    transmissionList,
    driveList
  ] = await Promise.all([
    fetchLovMakes({ active: true }, headers),
    fetchLovModels(
      {
        filter: {
          ids: [],
          makeIds: [],
          yearFromIncludeNull: true,
          active: true
        }
      },
      headers
    ),
    fetchLovBodies({ active: true }, headers),
    fetchLovFuelTypes({ active: true }, headers),
    fetchLovTransmissions({ active: true }, headers),
    fetchLovDrives({ active: true }, headers)
  ])

  return {
    makeList: makeList?.content ?? [],
    modelList: modelList?.content ?? [],
    bodiesList: bodyList ?? [],
    fuelTypeList: fuelTypeList?.content ?? [],
    transmissionList: transmissionList?.content ?? [],
    driveList: driveList ?? []
  }
}

export type SerpLovs = Awaited<ReturnType<typeof prefetchSerpLov>>

/**
 * This atom contains the LOVs for URL matching
 * Without it, we won't be able to validate, if the URL is valid or not
 */
export const SerpLovsAtom = atom<SerpLovs>({
  makeList: [],
  modelList: [],
  bodiesList: [],
  fuelTypeList: [],
  transmissionList: [],
  driveList: []
})
