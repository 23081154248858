import { logGAEventWithGtag } from 'driverama-core/analytics/events'
import {
  AnalyticsEvent,
  ExtractEventParameters,
  GetEventNames
} from 'driverama-core/analytics/types'
import { Maybe } from 'driverama-core/utils/types'
import { FaqSourcePage, PurchaseWizardStepGroup } from './useLogEvents'

type GAEvents =
  | AnalyticsEvent<'application_submit', { job: string; url: string }>
  | AnalyticsEvent<
      'user_registered',
      {
        provider: 'email' | 'facebook' | 'google' | 'apple' | 'phone'
        flow: 'generic' | 'sell' | 'buying' | 'email' | 'purchase-wizard'
      }
    >
  | AnalyticsEvent<
      'sell_page_view',
      {
        logged_in: 'yes' | 'no'
        page: 'landing' | 'search_cars' | 'trade_in' | 'finance' | 'external'
        button: 'header' | 'footer' | 'cta_button' | 'none'
      }
    >
  | AnalyticsEvent<
      'sell_video_watched',
      { logged_in: 'yes' | 'no'; button: 'upper' | 'lower' | undefined }
    >
  | AnalyticsEvent<
      'faq_open',
      { logged_in: 'yes' | 'no'; faq_name: string; faq_type: FaqSourcePage }
    >
  | AnalyticsEvent<'branch_view', { logged_in: 'yes' | 'no' }>
  | AnalyticsEvent<
      'vin_entered',
      { logged_in: 'yes' | 'no'; result: 'correct' | 'wrong' }
    >
  | AnalyticsEvent<
      'wizard_entered',
      { logged_in: 'yes' | 'no'; source_field: 'upper' | 'lower' | undefined }
    >
  | AnalyticsEvent<
      'basics_entered',
      {
        logged_in: 'yes' | 'no'
        type: 'vin' | 'manual'
        make: string
        model: string
        year: number
        kilometres: number
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
        body_type: string
        drive_type: string
        transmission: string
        fuel_type: string
        engine: string
        series: string
        model_line: string
      }
    >
  | AnalyticsEvent<
      'car_features_entered',
      {
        logged_in: 'yes' | 'no'
        features: string
        cta_button: 'continue' | 'skip'
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'condition_entered',
      {
        logged_in: 'yes' | 'no'
        accident: 'yes' | 'no'
        interior: number
        exterior: number
        mechanical: number
        customer_type: 'private' | 'self_employed' | 'company' // TODO: delete
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'price_generated',
      {
        logged_in: 'yes' | 'no'
        lower: number
        upper: number
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'appointment_proceeded',
      {
        logged_in: 'yes' | 'no'
        button: 'upper' | 'lower'
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'branch_selected',
      {
        logged_in: 'yes' | 'no'
        type: 'branch' | 'home'
        country: string
        city: string
        price: 'free' | 'paid'
        amount: number | null
        currency: string | null
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'date_booked',
      {
        logged_in: 'yes' | 'no'
        type: 'branch' | 'home'
        price: 'free' | 'paid'
        amount: number | null
        currency: string | null
        date: string
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'booking_confirmed',
      {
        type: 'log_in' | 'email' | 'facebook' | 'google' | 'apple' | 'phone'
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'wizard_booking_date_no_free_spots',
      {
        logged_in: 'yes' | 'no'
        branch_id?: Maybe<string>
        branch_name: string | undefined
        vin_code: string | undefined
        user_id: string | undefined
        opportunity_id: string | undefined
      }
    >
  | AnalyticsEvent<'vin_with_uncommon_error_inserted', { code: string }>
  | AnalyticsEvent<'landing_searched', { query: string }>
  | AnalyticsEvent<
      'calculator_interaction',
      {
        page: 'finance_page' | 'sell_landing' | 'car_detail'
        car_price: number
        loan_length: number
        down_payment: number
        monthly: number
        interest_rate: number | undefined
        APR: number
      }
    >
  | AnalyticsEvent<
      'car_detail_showed',
      {
        source_page:
          | 'sell_landing'
          | 'search_cars'
          | 'finance_page'
          | 'delivery_page'
          | 'car_detail'
          | 'registration_page'
          | 'direct'
          | 'warranty_page'
          | 'driverama_standard_page'
        source_section:
          | 'search_results'
          | 'carousel'
          | 'new_arrivals'
          | 'hot_deals'
          | 'financing_cars'
          | 'direct'
        car_id: string | undefined
        price: number | undefined
        loan_price: number | undefined | null
        year: number | undefined | null
        firstRegistrationDate: string | undefined
        mileage: number | undefined | null
        engine: string | undefined
        engine_power: number | undefined
        fuel: string | undefined
        transmission: string | undefined
        drive: string | undefined
        color: string | undefined
        body_type: string | undefined
        estimated_delivery: string | undefined
        label: string | undefined | null
        highlight: string | string[] | undefined
        car_make: string | undefined
        car_model: string | undefined
      }
    >
  | AnalyticsEvent<
      'inspection_showed',
      { button: 'upper_button' | 'lower_button'; car_id: string | undefined }
    >
  | AnalyticsEvent<
      'car_photo_action',
      { action: 'open' | 'browse'; car_id: string | undefined }
    >
  | AnalyticsEvent<
      'carousel_action',
      {
        action: 'tier_up' | 'tier_down' | 'randomize' | 'browse'
        page: 'car_detail' | 'cars_search'
      }
    >
  | AnalyticsEvent<'inspection_pdf_downloaded', { car_id: string | undefined }>
  | AnalyticsEvent<'imperfection_photo_viewed', { car_id: string | undefined }>
  | AnalyticsEvent<
      'imperfection_viewed',
      {
        section:
          | 'interior'
          | 'exterior'
          | 'mechanical'
          | 'history'
          | 'parameters'
        car_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'purchase_started',
      {
        source_page: 'car_detail' | 'inspection_report'
        car_id: string | undefined
        order_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'owner_selected',
      {
        person_type: 'private' | 'self_employed' | 'company'
        car_id: string | undefined
        order_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'country_selected',
      {
        country: string
        car_id: string | undefined
        order_id: string | undefined
      }
    >
  | AnalyticsEvent<
      'delivery_location_viewed',
      {
        car_id: string | undefined
        order_id: string | undefined
        branch_id: string | undefined
        branch_address: string | undefined
        fee: number
      }
    >
  | AnalyticsEvent<
      'delivery_location_selected',
      {
        car_id: string | undefined
        order_id: string | undefined
        branch_id: string | undefined
        branch_address: string | undefined
        fee: number
      }
    >
  | AnalyticsEvent<
      'car_registration_selected',
      {
        car_id: string | undefined
        order_id: string | undefined
        option: 'yes' | 'no'
        fee: number | undefined
      }
    >
  | AnalyticsEvent<
      'owner_details_entered',
      {
        car_id: string | undefined
        order_id: string | undefined
        pickup_person: 'same_as_owner' | 'different'
      }
    >
  | AnalyticsEvent<
      'general_condition_viewed',
      { car_id: string | undefined; order_id: string | undefined }
    >
  | AnalyticsEvent<
      'proceeded_to_payment_method',
      {
        car_id: string | undefined
        order_id: string | undefined
        final_price: Maybe<number>
      }
    >
  | AnalyticsEvent<
      'payment_method_selected',
      {
        car_id: string | undefined
        order_id: string | undefined
        method: 'bank_transfer' | 'loan'
      }
    >
  | AnalyticsEvent<
      'proceeded_to_order_tracking',
      {
        car_id: string | undefined
        order_id: string | undefined
        selling_opportunity_id?: string | undefined
      }
    >
  | AnalyticsEvent<
      'book_appointment_open',
      {
        order_id: string | undefined
        selling_opportunity_id?: string | undefined
      }
    >
  | AnalyticsEvent<
      'appointment_booked',
      {
        order_id: string | undefined
        selling_opportunity_id?: string | undefined
      }
    >
  | AnalyticsEvent<
      'authorization_passed',
      {
        car_id: string | undefined
        order_id: string | undefined
        type: 'phone_number' | 'email' | 'logged_in'
      }
    >
  | AnalyticsEvent<'search_term_added', { keyword: string | undefined }>
  | AnalyticsEvent<'make_model_filter_set', { make?: string; model?: string[] }>
  | AnalyticsEvent<
      'price_filter_set',
      { vat_deduction?: boolean; lower_range?: number; upper_range?: number }
    >
  | AnalyticsEvent<
      'years_mileage_filter_set',
      {
        lower_year?: number
        upper_year?: number
        lower_mileage?: number
        upper_mileage?: number
      }
    >
  | AnalyticsEvent<
      'power_filter_set',
      { units: 'kw' | 'hp'; lower_range?: number; upper_range?: number }
    >
  | AnalyticsEvent<
      'cubic_filter_set',
      { lower_range?: number; upper_range?: number }
    >
  | AnalyticsEvent<'body_type_filter_set', { body_types?: string[] }>
  | AnalyticsEvent<'features_filter_set', { features?: string[] }>
  | AnalyticsEvent<'fuel_filter_set', { fuel?: string[] }>
  | AnalyticsEvent<'transmission_filter_set', { transmission?: string[] }>
  | AnalyticsEvent<'color_filter_set', { color?: string[] }>
  | AnalyticsEvent<'drive_type_filter_set', { drive_type?: string }>
  | AnalyticsEvent<'sort_by_set', { value: string }>
  | AnalyticsEvent<'search_bar_cleared'>
  | AnalyticsEvent<'filter_cleared', { filter: string }>
  | AnalyticsEvent<'load_more'>
  | AnalyticsEvent<'next_page', { page_number: number }>
  | AnalyticsEvent<
      'ga.pageview',
      { pageURL: string; pageType: string; pageTitle: string }
    >
  | AnalyticsEvent<
      'cta_financing_clicked',
      { button: 'loan_options' | 'linear_loan' | 'balloon_loan' }
    >
  | AnalyticsEvent<
      'financing_form_interaction',
      {
        form_location?:
          | 'landing_page'
          | 'financing_page'
          | 'linear_loan'
          | 'balloon_loan'
      }
    >
  | AnalyticsEvent<'faq_link_clicked'>
  | AnalyticsEvent<
      'phone_number_clicked',
      {
        page?: 'car_detail' | 'purchase_wizard' | 'contact_us' | 'footer'
        wizard_step: PurchaseWizardStepGroup | null
      }
    >
  | AnalyticsEvent<'book_testdrive', { car_id: string | undefined }>
  | AnalyticsEvent<'testdrive_form_sent', { car_id: string | undefined }>

// NOTE: see more info here: https://driverama.atlassian.net/browse/SELL-653
type UAEvents =
  | AnalyticsEvent<'AddToCart'>
  | AnalyticsEvent<'Basics_01'>
  | AnalyticsEvent<'Delivery_02'>
  | AnalyticsEvent<'Car_Registration_03'>
  | AnalyticsEvent<'Future_Owner_04'>
  | AnalyticsEvent<'Order_Summary_05'>
  | AnalyticsEvent<'Payment_Method_06'>
  | AnalyticsEvent<'Checkout_07', { totalWithoutVat: number }>

export type GAEventNames = GetEventNames<GAEvents>
export type UAEventNames = GetEventNames<UAEvents>

/** @deprecated */
export function logGAEvent<EventName extends GAEventNames>(
  eventName: EventName,
  parameters?: ExtractEventParameters<EventName, GAEvents>
) {
  logGAEventWithGtag(eventName, parameters)
}
