import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovDrivesResponse = operations['getCarDrives']['responses']['200']['content']['application/com.driverama-v1+json']
export type LovDrive = LovDrivesResponse[0]
type QueryParams = operations['getCarDrives']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovDrivesResponse>

export async function fetchLovDrives(
  searchParams?: QueryParams,
  headers?: HeadersInit
) {
  const res = await apiFetcher<LovDrivesResponse>(URLS.lovDrives, {
    searchParams,
    headers
  })

  return res.json
}

export function useLovDrivesQuery(params?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovDrives(params),
    async () => fetchLovDrives(params),
    { ...lovQueryOptions, ...opts }
  )
}

export function useDriveList() {
  const { data, ...rest } = useLovDrivesQuery({ active: true })

  const drives = useMemo(() => {
    return (
      data?.map(drive => ({
        label: drive.name || drive.id,
        value: drive.id
      })) || []
    )
  }, [data])

  return { drives, ...rest }
}

export function getDriveDetailQueryParams(): QueryParams {
  return { active: true }
}

export function useDriveDetailQuery(driveId: Maybe<string>, opts?: QueryOpts) {
  const queryResult = useLovDrivesQuery(getDriveDetailQueryParams(), {
    enabled: !!driveId,
    ...opts
  })

  const drive = useMemo(() => {
    return queryResult.data?.find(item => item.id === driveId)
  }, [driveId, queryResult.data])

  return { ...queryResult, drive }
}
