import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovBodiesResponse = operations['getBodies']['responses']['200']['content']['application/com.driverama-v1+json']
export type LovBody = LovBodiesResponse[0]
type QueryParams = operations['getBodies']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovBodiesResponse>

export async function fetchLovBodies(
  searchParams?: QueryParams,
  headers?: HeadersInit
) {
  const res = await apiFetcher<LovBodiesResponse>(URLS.lovBodies, {
    searchParams,
    headers
  })

  return res.json
}

export function useLovBodiesQuery(
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovBodies(searchParams),
    async () => fetchLovBodies(searchParams),
    { ...lovQueryOptions, ...opts }
  )
}

export function useBodyList(
  modelIds?: Maybe<string[]>,
  makeIds?: Maybe<string[]>
) {
  const { data, ...rest } = useLovBodiesQuery({
    active: true,
    // TODO wait for BE to accept multiple modelIds and MakeIds
    modelId: modelIds ? modelIds[0] : undefined,
    makeId: makeIds ? makeIds[0] : undefined
  })

  const bodies = useMemo(
    () =>
      data?.map(body => ({ label: body.name || body.id, value: body.id })) ||
      [],
    [data]
  )

  return { ...rest, bodies }
}

export function getBodyDetailQueryParams(bodyId: Maybe<string>): QueryParams {
  return { active: true, id: bodyId ?? undefined }
}

export function useBodyDetailQuery(bodyId: Maybe<string>, opts?: QueryOpts) {
  const queryResult = useLovBodiesQuery(getBodyDetailQueryParams(bodyId), {
    enabled: !!bodyId,
    ...opts
  })

  const body = useMemo(() => {
    return queryResult.data?.find(item => item.id === bodyId)
  }, [bodyId, queryResult.data])

  return { ...queryResult, body }
}
