import { Atom, SECRET_INTERNAL_getScopeContext } from 'jotai'
import { useContext, useEffect, useRef } from 'react'
import { Scope } from 'jotai/core/atom'

const RESTORE_ATOMS = 'h'

/**
 * As the useHydrateAtoms function caches the set
 * of atoms used for hydration, it results in stale data
 * at least on development environment.
 * Thus, we've copied the useHydrateAtoms logic, removing the
 * caching mechanism.
 */
export function useNaiveHydrateAtoms(
  values: Iterable<readonly [Atom<unknown>, unknown]>,
  scope?: Scope
) {
  const rehydratedRef = useRef<boolean>(false)

  const ScopeContext = SECRET_INTERNAL_getScopeContext(scope)
  const scopeContainer = useContext(ScopeContext)
  const store = scopeContainer.s

  const tuplesToRestore = []
  for (const tuple of values) {
    tuplesToRestore.push(tuple)
  }

  // prevent rehydration after rerender
  useEffect(() => {
    rehydratedRef.current = true
  }, [])

  if (tuplesToRestore.length && !rehydratedRef.current) {
    store[RESTORE_ATOMS](values)
  }
}
