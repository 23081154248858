import { useMemo } from 'react'
import { atom, useAtom } from 'jotai'
import { focusAtom } from 'jotai/optics'
import {
  SearchInputControls,
  SearchControlsState,
  SearchState,
  SerpState
} from './Serp.types'
import {
  initialSearchState,
  initialSerpState,
  initialInputControls
} from './SerpConstants'
import { useDebouncedCallback } from 'use-debounce'

/**
 * global configuration atom for hits per page
 * TODO: this might be extracted into a constant instead
 */
export const SerpConfigurationAtom = atom({ hitsPerPage: 16 })

/**
 * handles the local state of SerpInput and make ID, to show the correct UI
 * Does not interact with Algolia, neither the main filter UI whatsoever
 */
export const SerpInputControlsAtom = atom(initialInputControls)

export function useSerpInputControlAtom<T extends keyof SearchInputControls>(
  name: T
) {
  const atom = useMemo(
    () => focusAtom(SerpInputControlsAtom, optic => optic.prop(name)),
    [name]
  )

  return useAtom(atom)
}

/**
 * UI state of the filters, facets, keywords etc.
 */
export const SerpStateAtom = atom<SerpState>(initialSerpState)

/**
 * Atom, which is used to update search params for Algolia
 * In general, the SerpAlgoliaAtom mirrors the SerpStateAtom after a debounce
 */
export const SerpAlgoliaAtom = atom<SearchState>(initialSearchState)

/**
 * Atom used to show values of filters, optimistic UI
 */
export const SerpLocalAtom = focusAtom(SerpStateAtom, optic =>
  optic.prop('search')
)

export const useSerpAtom = <T extends keyof SearchState>(
  category: T,
  attribute: keyof SearchState[T],
  options?: { ui: boolean }
) => {
  const atom = useMemo(
    () =>
      focusAtom(SerpAlgoliaAtom, optic => optic.prop(category).prop(attribute)),
    [category, attribute]
  )

  const atomUI = useMemo(
    () =>
      focusAtom(SerpLocalAtom, optic => optic.prop(category).prop(attribute)),
    [category, attribute]
  )

  return useAtom(options?.ui ? atomUI : atom)
}

export const useQueryAtom = (options?: { ui: boolean }) => {
  const atom = useMemo(
    () =>
      focusAtom(SerpAlgoliaAtom, optic => optic.prop('attributeForMyQuery')),
    []
  )
  const atomUI = useMemo(
    () => focusAtom(SerpLocalAtom, optic => optic.prop('attributeForMyQuery')),
    []
  )

  return useAtom(options?.ui ? atomUI : atom)
}

export const useSortByAtom = (options?: { ui: boolean }) => {
  const atom = useMemo(
    () => focusAtom(SerpAlgoliaAtom, optic => optic.prop('sortBy')),
    []
  )
  const atomUI = useMemo(
    () => focusAtom(SerpLocalAtom, optic => optic.prop('sortBy')),
    []
  )

  return useAtom(options?.ui ? atomUI : atom)
}

export const useControlsAtom = <T extends keyof SearchControlsState>(
  name: T
) => {
  const atom = useMemo(
    () => focusAtom(SerpStateAtom, optic => optic.prop('controls').prop(name)),
    [name]
  )

  return useAtom(atom)
}

/**
 * Call all functions related to changing results by user
 */
export const useSerpOnChangeFunctions = () => {
  const [page, setPage] = useControlsAtom('page')

  const resetPage = useDebouncedCallback(() => {
    setPage(1)
  }, 500)

  const serpOnChange = () => {
    if (page !== 1) {
      resetPage()
    }
  }

  return { serpOnChange }
}
