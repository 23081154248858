import { parse, isAfter, isWeekend } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const getLocalDate = () =>
  utcToZonedTime(new Date(), process.env.NEXT_PUBLIC_SUPPORT_TIMEZONE as string)

export const isLocalWeekend = () => {
  return isWeekend(getLocalDate())
}

export const areWorkingHours = (workingHours: { [key: number]: string[] }) => {
  const localDate = getLocalDate()
  const currentDay = localDate.getDay()

  const currentDayHoursStart = workingHours[currentDay][0]
  const currentDayHoursEnd = workingHours[currentDay][1]

  if (!currentDayHoursStart || !currentDayHoursEnd) {
    return false
  }

  const workingDaysStart = parse(currentDayHoursStart, 'H:m', new Date())
  const workingDaysEnd = parse(currentDayHoursEnd, 'H:m', new Date())

  return (
    isAfter(localDate, workingDaysStart) && !isAfter(localDate, workingDaysEnd)
  )
}
