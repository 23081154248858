import { LinearLoanCalculatorResponse } from 'driverama-core/api/driverama/loanCalculator/loanCalculator'
import { CarResponse } from 'driverama-core/api/driverama/stock/stockCarDetail'
import { CarTag } from 'driverama-core/utils/tags'
import { ArrayElementType } from 'driverama-core/utils/types'
import { z } from 'zod'

export type BodyTypeName =
  | 'H'
  | 'S'
  | 'C'
  | 'SUV'
  | 'OFF'
  | 'VAN'
  | 'BV'
  | 'CPE'
  | 'CAB'
  | 'OTHER'

export const AlgoliaStockCarDto = z.object({
  objectID: z.string(),
  stockNo: z.string(),
  makeId: z.string(),
  modelId: z.string(),
  engineId: z.string(),
  enginePowerPs: z.number(),
  enginePowerKw: z.number(),
  engineCylinders: z.number(),
  engineVolumeCcm: z.number(),
  reserved: z.boolean(),
  makeName: z.string(),
  modelName: z.string(),
  engineName: z.string(),
  sourceCountry: z.string(),
  source: z.union([z.literal('DRIVERAMA'), z.literal('AURES')]),

  ownersCount: z.number().nullish(),
  extendedModelId: z.string().nullish(),
  modelLineId: z.string().nullish(),
  modelLineName: z.string().nullish(),
  bodyId: z.string().nullish(),
  doorsCount: z.number().nullish(),
  seatsCount: z.number().nullish(),
  fuelTypeLabels: z.array(z.string()).nullish(),
  transmissionId: z.string().nullish(),
  driveId: z.string().nullish(),
  colorId: z.string().nullish(),
  metallicColor: z.boolean().nullish(),
  vatDeductible: z.boolean().nullish(),
  yearOfMake: z.number().nullish(),
  firstRegistrationYear: z.number().nullish(),
  actualMileageKm: z.number().nullish(),
  features: z.array(z.string()).nullish(),
  extendedModelName: z.string().nullish(),
  bodyNameEn: z.string().nullish(),
  bodyNameDe: z.string().nullish(),
  prices: z
    .array(
      z.object({
        fullPriceNoVatEur: z.number(),
        fullPriceEur: z.number(),
        // stop using when https://driverama.atlassian.net/browse/STOK-1727 is done
        monthlyLoanInstalmentNoVatEur: z.number().nullish(),
        // stop using when https://driverama.atlassian.net/browse/STOK-1727 is done
        monthlyLoanInstalmentEur: z.number().nullish(),
        loan: z
          .object({
            interestRatePercentage: z.number(),
            downPaymentPercentage: z.number(),
            downPaymentValueEur: z.number(),
            registrationFeeEur: z.number(),
            loanDurationMonths: z.number(),
            monthlyInstallmentEur: z.number(),
            monthlyInstallmentEurRounded: z.number(),
            firstInstallmentEur: z.number(),
            totalLoanAmountEur: z.number(),
            netLoanAmountEur: z.number(),
            apr: z.number()
          })
          .optional()
          .nullable()
      })
    )
    .nullish(),
  photos: z
    .array(
      z.object({
        order: z.number(),
        url: z.string(),
        fileId: z.string().nullish()
      })
    )
    .nullish()
})

export enum SearchParameterQueryNames {
  QUERY = 'query',
  FIRST_REGISTRATION_YEAR_MIN = 'firstRegistrationYearMin',
  FIRST_REGISTRATION_YEAR_MAX = 'firstRegistrationYearMax',
  MILEAGE_MIN = 'actualMileageKmMin',
  MILEAGE_MAX = 'actualMileageKmMax',
  FULL_PRICE_EUR_MIN = 'fullPriceEurMin',
  FULL_PRICE_EUR_MAX = 'fullPriceEurMax',
  MONTHLY_INSTALLMENT_EUR_MIN = 'monthlyPaymentEurMin',
  MONTHLY_INSTALLMENT_EUR_MAX = 'monthlyPaymentEurMax',
  ENGINE_VOLUME_MIN = 'engineVolumeCcmMin',
  ENGINE_VOLUME_MAX = 'engineVolumeCcmMax',
  ENGINE_POWER_KW_MIN = 'enginePowerKwMin',
  ENGINE_POWER_KW_MAX = 'enginePowerKwMax',
  ENGINE_POWER_PS_MIN = 'enginePowerPsMin',
  ENGINE_POWER_PS_MAX = 'enginePowerPsmax',
  FUEL_TYPE = 'fuelType',
  TRANSMISSION = 'transmission',
  COLOR = 'color',
  MODEL = 'model',
  BODY = 'body',
  FEATURES = 'features',
  DRIVE = 'drive',
  SEATS = 'seatsCount',
  DOORS = 'doorsCount',
  ENGINE_CYLINDERS = 'engineCylinders',
  METALLIC_COLOR = 'metallicColor',
  VAT_DEDUCTIBLE = 'vatDeductible',
  SOURCE = 'source',
  SORT_BY = 'sortBy'
}

export enum ControlParameterQueryNames {
  NOTIFY = 'notify',
  SEATS_COUNT_EXACT = 'seatsCountExact',
  POWER_UNITS = 'powerUnits',
  PAGE = 'page'
}

export interface AlgoliaStockCarResponse
  extends z.infer<typeof AlgoliaStockCarDto> {
  queryID: string
  marketingHighlights?: Array<
    ArrayElementType<NonNullable<CarResponse['marketingHighlights']>>
  > | null
  marketingLabel?: NonNullable<CarResponse['marketingLabel']> | null
}

export const AlgoliaStockCarsSuggestionDto = z.object({
  nb_words: z.number(),
  popularity: z.number(),
  query: z.string(),
  objectID: z.string(),
  _highlightResult: z.object({
    query: z.object({
      value: z.string().nullish(),
      matchLevel: z.string().nullish(),
      fullyHighlighted: z.boolean().nullish(),
      matchedWords: z.array(z.string()).nullish()
    })
  })
})

export interface AlgoliaStockCarsSuggestion
  extends z.infer<typeof AlgoliaStockCarsSuggestionDto> {
  prod_stock_cars: {
    exact_nb_hits: number
    facets: {
      exact_matches: Record<string, unknown>
      analytics: Record<string, unknown>
    }
  }
  __position: number
}

export interface CarListData {
  name: string
  image?: string
  mileage?: number | null
  year?: number | null
  price?: number | null
  priceMonthly?: number | null
  transmission?: string | null
  loanData?: Omit<
    LinearLoanCalculatorResponse[number],
    'subjectPriceIncludingVatEur' | 'upsales'
  >
  fuelType?: string | null
  powerKw: number
  tag?: CarTag
  features?: {
    id: string
    name: string | undefined
    active: boolean
    categoryId: string | undefined
  }[]
  ownersCount?: number | null
}

export interface SearchInputControls {
  keyword: string
  makeId?: string
}

export interface SearchControlsState {
  notify: boolean
  seatsCountExact: boolean
  powerUnits: 'kw' | 'hp'
  page: number
}

export type CarFinderValueItemAttributes =
  | undefined
  | { id: string; order: number }[]

export interface SerpFiltersFormRange {
  downPaymentFrom: number
  downPaymentTo: number

  monthlyInstallmentFrom: number
  monthlyInstallmentTo: number

  priceRangeFrom: number
  priceRangeTo: number

  yearFrom: number
  yearTo: number

  mileageFrom: number
  mileageTo: number

  consumptionFrom: number
  consumptionTo: number

  powerKwFrom: number
  powerKwTo: number

  powerHpFrom: number
  powerHpTo: number

  cubicCapacityFrom: number
  cubicCapacityTo: number
}

export interface SerpFiltersFormRefinementList {
  modelIds: string[]
  keywords: string[]
  bodyIds: string[]
  features: string[]
  fuelTypes: string[]
  transmissions: string[]
  colorId: string[]
}

export interface SerpFiltersFormMenu {
  driveId?: string
  seatsCount?: string
  doorsCount?: string
}

export interface SerpFiltersFormToggleRefinement {
  metallicColor: boolean
  vatDeductible: boolean
}

type SerpFiltersFormUnion = SerpFiltersFormRange &
  SerpFiltersFormRefinementList &
  SerpFiltersFormMenu &
  SerpFiltersFormToggleRefinement

export interface SerpFiltersForm extends SerpFiltersFormUnion {
  attributes: CarFinderValueItemAttributes
  keyword: string
  notify: boolean
  express: boolean
  makeId?: string
  seatsCountExact: boolean
  cylinders?: string
  colorMatte: boolean
  interiorMaterial?: string
  interiorColor?: string

  // TODO: temporary
  numberOfSeats?: number
  numberOfSeatsExact: boolean
  numberOfDoors?: string
}

export type SearchStateRangeValue =
  | {
      min: number
      max: number
    }
  | Record<string, never>

type SearchStateQuery = string

type SearchStateRefinementListValue = string[]

type SearchStateMenuValue = string

type SearchStateToggle = boolean

type SearchStateSortBy = string

export interface SearchState {
  attributeForMyQuery: SearchStateQuery
  range: {
    firstRegistrationYear: SearchStateRangeValue
    actualMileageKm: SearchStateRangeValue
    'prices.fullPriceEur': SearchStateRangeValue
    'prices.loan.monthlyInstallmentEur': SearchStateRangeValue
    engineVolumeCcm: SearchStateRangeValue
    enginePowerKw: SearchStateRangeValue
    enginePowerPs: SearchStateRangeValue
  }
  refinementList: {
    fuelTypeLabels: SearchStateRefinementListValue
    transmissionId: SearchStateRefinementListValue
    colorId: SearchStateRefinementListValue
    modelId: SearchStateRefinementListValue
    bodyId: SearchStateRefinementListValue
    features: SearchStateRefinementListValue
    source: SearchStateRefinementListValue
  }
  menu: {
    driveId: SearchStateMenuValue
  }
  /** multiRange is NumericMenu */
  multiRange: {
    seatsCount: SearchStateMenuValue
    doorsCount: SearchStateMenuValue
    engineCylinders: SearchStateMenuValue
  }
  toggle: {
    metallicColor: SearchStateToggle
    vatDeductible: SearchStateToggle
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  configure: {}
  sortBy: SearchStateSortBy
}

export interface SerpState {
  search: SearchState
  controls: SearchControlsState
}

export type FiltersTypes =
  | keyof SearchState['range']
  | keyof SearchState['refinementList']
  | keyof SearchState['menu']
  | keyof SearchState['multiRange']
  | keyof SearchState['toggle']

export type FilterTypesWithAttributes =
  | 'range'
  | 'refinementList'
  | 'menu'
  | 'multiRange'
  | 'toggle'
  | 'configure'

export type FilterTypesWithoutAttributes = 'attributeForMyQuery' | 'sortBy'
